import {Injectable} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  private loginForm: FormGroup;

  constructor() { }

  get data() {
    return this.loginForm;
  }

  set data(data) {
    this.loginForm = data;
  }
}
