import {Directive, HostListener, Input} from '@angular/core';
import {ExcelExportService} from "../services/excel-export.service";

@Directive({
  selector: '[excelExport]'
})
  export class ExcelExportDirective {

  constructor(private exportService: ExcelExportService) { }

  @Input('excelExport') data: any[];
  @Input() fileName: string;

  @HostListener('click', ['$event']) onClick($event) {
    this.exportService.exportExcel(this.data, this.fileName);
  }

}
