import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/home', title: 'Inicio', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // {
  //   path: '', title: 'Usuarios', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
  //     { path: '/users', title: 'Listar usuarios', icon: 'ft-list submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/users-add', title: 'Nuevo usuario', icon: 'ft-plus submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  //   ]
  // },
  // {
  //   path: '', title: 'Abonos', icon: 'ft-credit-card', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
  //     { path: '/abonos', title: 'Listar abonos', icon: 'ft-list submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/buscar-abono', title: 'Buscar abono', icon: 'ft-search submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/nuevo-abono', title: 'Nuevo abono', icon: 'ft-plus submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  //   ]
  // },
  // {
  //   path: '', title: 'Escuela', icon: 'ft-layers', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
  //     { path: '/alumnos', title: 'Listar alumnos', icon: 'ft-list submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/buscar-alumno', title: 'Buscar alumno', icon: 'ft-search submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/nuevo-alumno', title: 'Nuevo alumno', icon: 'ft-plus submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/remesa-alumno', title: 'Remesa alumnos', icon: 'ft-dollar-sign submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  //   ]
  // },
  // {
  //   path: '', title: 'Eventos deportivos', icon: 'ft-calendar', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
  //     { path: '/eventos', title: 'Listar eventos', icon: 'ft-list submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/nuevo-evento', title: 'Nuevo evento', icon: 'ft-plus submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
  //   ]
  // },
  // {
  //   path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-rojo float-right mr-1 mt-1', isExternalLink: false,
  //   submenu: [
  //       { path: '/YOUR-ROUTE-PATH', title: 'Second Level', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //       {
  //           path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //           submenu: [
  //               { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //               { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //           ]
  //       },
  //   ]
  // }
];
