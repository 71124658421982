/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:993eea72-6f25-4daa-b7af-1e3bf477cedf",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_bqqkS02m8",
    "aws_user_pools_web_client_id": "6udv7c1a9djhestjeoghdgfftl",
    "oauth": {},
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
