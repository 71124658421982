import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {FormGroup} from '@angular/forms';
import {SecureStorageService} from '../services/secure-storage.service';

@Injectable()
export class AuthService {

  constructor(public router: Router, private storage: SecureStorageService) {
  }

  signupUser(userForm: FormGroup, attributes: FormGroup): Promise<any> {
    return Auth.signIn(userForm.value.username, userForm.value.password)
    .then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(
            user,
            attributes.value.newPassword,
            {
              name: attributes.value.name,
              middle_name: attributes.value.middleName,
              phone_number: attributes.value.phoneNumber
            }
        ).then(user => {
          return user;
        }).catch(error => {
          console.log('error: ', error);
        });
      } else {
        // other situations...
      }
    });
  }

  loginUser(form: FormGroup) {
    return Auth
    .signIn(form.value.username, form.value.password)
    .then(user => {
      return this.getCurrentUserInfo().then(info => {
        Auth.userAttributes(user).then(data => console.log(data));
        localStorage.setItem('userInfo', JSON.stringify(info));
        return user;
      });
    });
  }

  logout() {
    Auth.signOut({global: true}).then(() => {
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }

  recoveryPassword(username: string) {
    return Auth.forgotPassword(username);
  }

  setupNewPassword(username: string, code: string, newPassword: string) {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  isAuthenticated(): Promise<boolean> {
    return Auth.currentAuthenticatedUser()
    .then(response => {
      if (response === 'not authenticated') {
        return false;
      } else {
        return true;
      }
    });
  }

  private getCurrentUserInfo(): Promise<any> {
    return Auth.currentUserInfo();
  }
}
